<template>
  <div class='ServiceInformation'>
    <div class='ServiceInformation__title'>
      <label
        v-if="displayTitle"
        class='ServiceInformation__label'
      >
        {{ title }}
      </label>
      <div
        class="flex justify-end"
        :class="{'w-100': !displayTitle}"
      >
        <button
          class="slick-prev"
          @click="decrementIndex"
          :disabled="activeIndex === 0"
        >
          Previous
        </button>
        <button
          class="slick-next"
          @click="incrementIndex"
          :disabled="activeIndex === allLength - 1"
        >
          Next
        </button>
      </div>
    </div>
    <ui-loader v-if="loading" />
    <div v-else-if="allLength > 0">
      <div
        v-for="(data, index) in all"
        :key="index"
        v-show="activeIndex === index"
      >
        <p class='ServiceInformation__updateTime'>
          Updated: {{data.updateTime}}
        </p>
        <p class='ServiceInformation__content'>
          {{data.TEXT['$']}}
        </p>
      </div>
    </div>
    <div v-else>
      <p>Nothing to report.</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Loader from '@/components/ui/Loader/Loader.vue'

export default Vue.extend({
  components: {
    'ui-loader': Loader
  },
  props: {
    loading: Boolean,
    all: {
      default: () => ([])
    },
    title: {
      type: String,
      default: 'Service Information'
    },
    displayTitle: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    activeIndex: 0
  }),
  computed: {
    allLength () {
      return this.all ? this.all.length : 0
    }
  },
  methods: {
    incrementIndex () {
      this.activeIndex < this.allLength - 1 && this.activeIndex++
    },
    decrementIndex () {
      this.activeIndex > 0 && this.activeIndex--
    }
  },
  watch: {
    all: {
      immediate: true,
      handler (newValue) {
        newValue && (this.activeIndex = newValue.length - 1)
      }
    }
  }
})
</script>

<style lang="postcss" scoped>

@import url('./slick/style.css');

.ServiceInformation {
  &__title {
    display: flex;
    align-items: center;

    label {
      margin-right: auto;
    }

    .ui-button {
      width: 1.5em;
      border-radius: 50%;
      margin-left: calc($default-spacing / 2);
    }

    /**
    * Navigation buttons
    */
    & .slick-next,
    & .slick-prev {
      border-radius: 100%;
    }

  }

  &__label {
    display: inline-block;
    color: var(--color-primary-light);
    font-size: 1.125em;
  }

  &__updateTime {
    color: var(--color-gold);
    font-size: 0.8em;
    font-style: italic;
    margin-bottom: $default-spacing;
  }
}
</style>
