

























































































import Vue from 'vue'
import ToggleSwitch from '@/components/ui/ToggleSwitch/ToggleSwitch.vue'
import Button from '@/components/ui/Button/Button.vue'
import VueSlider from 'vue-slider-component'
import palette from './palette'

export default Vue.extend({
  name: 'map-legend',
  components: {
    'ui-toggle-switch': ToggleSwitch,
    'ui-button': Button,
    VueSlider
  },
  props: {
    isLayerOsmDisplayed: Boolean,
    isLayerRainpathDisplayed: Boolean,
    isLayerWeatherStationsDisplayed: Boolean,
    displayOsmOpacity: {
      type: Boolean,
      default: true
    },
    osmOpacity: {
      type: Number,
      required: false,
      default: 1
    },
    displayRadarOpacity: {
      type: Boolean,
      default: true
    },
    radarOpacity: {
      type: Number,
      required: false,
      default: 1
    },
    isLayerWindArrowsDisplayed: {
      type: Boolean,
      required: true
    },
    radarPeriods: {
      type: Array,
      required: false,
      default: () => ([])
    },
    displayRainPath: {
      type: Boolean,
      default: true
    },
    displayMapCenter: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    palette,
    showLegend: false
  })
})

