





































import Vue from 'vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import WeatherStationDetail from './WeatherStationDetail.vue'

export default Vue.extend({
  name: 'WeatherStationListing',
  components: {
    'ui-loader': Loader,
    'weather-station-detail': WeatherStationDetail
  },
  props: {
    loading: Boolean,
    stations: {
      type: Array,
      default: () => ([])
    },
    stationsData: {
      type: Array,
      default: () => ([])
    },
    currentStation: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: 'Ground stations'
    },
    displayTitle: {
      type: Boolean,
      default: true
    },
    displayBadges: {
      type: Boolean,
      default: true
    },
    displayTime: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    currentStationIndex: null
  }),
  computed: {
    currentStationsData () {
      if (this.currentStationIndex) {
        return this.stationsData[this.currentStationIndex]
      } else {
        return this.stationsData[this.currentStation]
      }
    }
  }
})
